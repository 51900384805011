<template>
  <div class="s-l-layout__progress" id="mobprogress">
    <ol class="s-timeline" aria-label="Ukazatal průběhu objednávky">
      <li class="s-timeline__item">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <a href="javascript:void(0)" class="s-timeline__link" title="Výběr lokality">
          <span class="s-timeline__text"> Výběr lokality </span>
        </a>
      </li>
      <li class="s-timeline__item">
        <span class="s-u-visualy-hidden"> Dokončený krok: </span>
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Výběr produktu </span>
        </span>
      </li>
      <li class="s-timeline__item s-timeline__item--current">
        <span class="s-u-visualy-hidden"> Aktuální krok: </span>
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Výběr termínu </span>
        </span>
      </li>
      <li class="s-timeline__item s-timeline__item--disabled">
        <span class="s-timeline__link">
          <span class="s-timeline__text"> Identifikace </span>
        </span>
      </li>
    </ol>
  </div>
  <div class="s-l-layout__left-side s-u-order-1" id="leftside">
    <div class="s-l-date s-u-mb-actions">
      <div class="s-l-date__text">
        <h2 class="s-h s-h--secondary d-none d-lg-block mb-2">Vyberte termín</h2>
        <p class="mb-lg-0">Doporučujeme vybírat čas Vaší návštěvy v době nižší vytíženosti odběrového místa.</p>
      </div>
      <div class="s-l-date__btns">
        <button
          type="button"
          class="s-btn s-btn--secondary s-btn--action text-uppercase mr-3"
          @click="loadPreviousDays"
        >
          <span class="d-block">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.3 9.3" width="9.3" height="9.3" class="d-block">
              <path fill="currentColor" d="M4.6 9.3l.8-.8-3.1-3.3h7V4h-7L5.5.8 4.6 0 0 4.6l4.6 4.7z" />
            </svg>
          </span>
          <span class="d-block pl-2"> Předcházejících 7&nbsp;dní </span>
        </button>
        <button type="button" class="s-btn s-btn--secondary s-btn--action text-uppercase" @click="loadNextDays">
          <span class="d-block pr-2"> Nasledujících 7&nbsp;dní </span>
          <span class="d-block">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.3 9.3" width="9.3" height="9.3" class="d-block">
              <path fill="currentColor" d="M4.6 9.3l-.8-.9L7 5.2H0V4h7L3.8.8l.8-.8 4.6 4.6-4.6 4.7z" />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <div class="s-d-table">
      <table class="s-d-table__table">
        <thead class="s-d-table__thead">
          <tr class="s-d-table__tr">
            <th class="s-d-table__th text-uppercase" scope="col">Čas</th>
            <th v-for="(day, index) in availableDays" :key="index" class="s-d-table__th" scope="col">
              <span class="s-u-fw-regular d-block mb-2">
                {{ getDay(day) }}
              </span>
              <span class="s-d-table__date"> {{ getDate(day) }} </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="time in times" :key="time" class="s-d-table__tr" scope="col">
            <th class="s-d-table__td s-d-table__td--th-t s-d-table__td--dark" scope="row">{{ time }}</th>
            <td
              v-for="(slot, index) in slots"
              :key="index"
              class="s-d-table__td"
              :class="{
                's-d-table__td--full': !getSlotAvailability(time, index) || getSlotAvailability(time, index) > 75,
              }"
            >
              <template v-if="getSlotAvailability(time, index) && getSlotAvailability(time, index) >= 100">
                <span class="s-u-visualy-hidden"> Termín je plný </span>
                {{ getSlotAvailability(time, index) }}%
              </template>
              <button
                v-else-if="getSlotAvailability(time, index)"
                type="button"
                class="s-d-table__link"
                aria-expanded="false"
                @click="handleOpenModal(slot, time)"
              >
                <span class="s-u-visualy-hidden"> Vybrat počet lidí na daný termín. Obsazeno procent: </span>
                {{ getSlotAvailability(time, index) }}%
              </button>
              <template v-else>Nedostupný</template>
            </td>
            <!--td
              v-for="(day, index) in days"
              :key="index"
              class="s-d-table__td"
              :class="{ 's-d-table__td--full': day.getSlotByTime(time).isNearlyFull }"
            >
              <template v-if="day.getSlotByTime(time).isFull">
                <span class="s-u-visualy-hidden"> Termín je plný </span>
                {{ day.getSlotByTime(time).filledPercents }}%
              </template>
              <button
                v-else
                type="button"
                class="s-d-table__link"
                @click="handleOpenModal(day, day.getSlotByTime(time))"
                aria-expanded="false"
              >
                <span class="s-u-visualy-hidden"> Vybrat počet lidí na daný termín. Obsazeno procent: </span>
                {{ day.getSlotByTime(time).filledPercents }}%
              </button>
            </td-->
          </tr>
        </tbody>
      </table>

      <transition name="fade">
        <modal v-if="pickedSlot" :show-modal="showModal" @close="handleCloseModal" @open="handleOpenModal">
          <h2 class="s-modal__title" id="dialog-1-title">POČET OSOB</h2>
          <!--p class="s-modal__text">Ve vybraném slotu jsou {{ pickedSlot.availableSlotsCount }} volná místa</p-->
          <input
            type="number"
            step="1"
            max="3"
            class="s-form__input s-form__input--modal"
            v-model="formData.customersCount"
          />
          <div class="s-modal__btn-box">
            <a href="javascript:void(0)" class="s-btn" @click.prevent="pickSlot"> Potvrdit </a>
          </div>
        </modal>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { XiteApiService } from '../../services/xite.api.service';
import Modal from '../../components/partials/Modal';

import moment from 'moment/min/moment-with-locales';

export default {
  name: 'ReservationDateTime',

  components: {
    Modal,
  },

  data() {
    return {
      showModal: false,
      pickedDay: null,
      pickedSlot: null,
      formData: {
        customersCount: 1,
      },
      startDay: null,
      showDays: [0, 6],
      availableDays: [],
      slots: [],
    };
  },

  computed: {
    ...mapGetters({ localization: 'languages/getBrowserLanguage' }),
    ...mapGetters({
      ordersFormData: 'orders/getFormData',
      me: 'me/getMe',
    }),

    times() {
      if (!this.slots.length) {
        return [];
      }
      let longestSlotsArray = Object.keys(this.slots[0]);

      this.slots.forEach(slot => {
        if (Object.keys(slot).length > longestSlotsArray.length) {
          longestSlotsArray = Object.keys(slot);
        }
      });

      return longestSlotsArray.filter(slot => slot !== 'date/time');
    },

    days() {
      return this.today;
    },
  },

  methods: {
    ...mapActions('orders', ['updateFormData']),

    dayDate(index) {
      console.log(index);
      return moment(this.startDay)
        .add(index + this.showDay, 'days')
        .format('D.M.YYYY');
    },

    getAvailableDays() {
      for (let i = this.showDays[0]; i <= this.showDays[1]; i++) {
        this.availableDays.push(moment(this.startDay).add(i, 'days'));
      }

      this.getSlots();
    },

    getDate(date) {
      return moment(date).format('D.M.YYYY');
    },

    getDay(date) {
      return moment(date).format('dddd');
    },

    loadNextDays() {
      this.showDays[0] += 7;
      this.showDays[1] += 7;
      this.availableDays = [];
      this.slots = [];
      this.getAvailableDays();
    },

    loadPreviousDays() {
      if (this.showDays[0] === 0) {
        return;
      }
      this.showDays[0] - 7 < 0 ? (this.showDays[0] = 0) : (this.showDays[0] -= 7);
      this.showDays[1] - 7 < 6 ? (this.showDays[1] = 6) : (this.showDays[1] -= 7);
      this.availableDays = [];
      this.slots = [];
      this.getAvailableDays();
    },

    getSlots() {
      const start = moment(this.availableDays[0]).format('YYYY-MM-DD');
      const limit = moment(this.availableDays[this.availableDays.length - 1])
        .add(1, 'days')
        .format('YYYY-MM-DD');
      XiteApiService.get('Slots', {
        instance: process.env.VUE_APP_INSTANCE_FILE,
        location: this.ordersFormData.locationId,
        start: start,
        limit: limit,
      }).then(slots => {
        let aSlots = slots.Get('csv').split('\n');
        var processedSlots = [];
        var headers = aSlots[0].split(',');
        for (var i = 1; i < aSlots.length; i++) {
          var data = aSlots[i].split(',');
          var obj = {};
          for (var j = 0; j < data.length; j++) {
            if (data[j].trim() !== '-') {
              obj[headers[j].trim()] = data[j].trim();
            }
          }
          if (Object.keys(obj).length > 1) {
            processedSlots.push(obj);
          }
        }
        JSON.stringify(processedSlots);

        this.slots = processedSlots;
      });
    },

    getSlotAvailability(time, slot) {
      return this.slots[slot][Object.keys(this.slots[slot]).find(slotTime => slotTime === time)];
    },

    handleOpenModal(day, slot) {
      this.showModal = true;
      this.pickedDay = day;
      this.pickedSlot = slot;

      document.addEventListener('keydown', this.closeModalOnKeydown);
    },

    handleCloseModal() {
      this.showModal = false;
      this.pickedDay = null;
      this.pickedSlot = null;
    },

    closeModalOnKeydown(event) {
      if (event.keyCode !== 27) {
        return;
      }

      this.handleCloseModal();
      document.removeEventListener('keydown', this.closeModalOnKeydown);
    },

    pickSlot() {
      const customers = new Array(this.formData.customersCount).fill().map((curr, index) => {
        console.log(index);
        curr = {
          name: this.me && index == 0 ? this.me.name : null,
          surname: this.me && index == 0 ? this.me.surname : null,
          birthdate: this.me && index == 0 ? this.me.birthdate : null,
          nationalid: this.me && index == 0 ? this.me.nationalid : null,
          nationality: this.me && index == 0 ? this.me.nationality : null,
          insurance: this.me && index == 0 ? this.me.insurance : null,
          phone: this.me && index == 0 ? this.me.phone : null,
          email: this.me && index == 0 ? this.me.email : null,
          street: this.me && index == 0 ? this.me.street : null,
          city: this.me && index == 0 ? this.me.city : null,
          zip: this.me && index == 0 ? this.me.zip : null,
          country: this.me && index == 0 ? this.me.country : null,
        };
        return curr;
      });

      const formData = {
        reservedDay: this.pickedDay['date/time'],
        reservedTime: this.pickedSlot,
        customers,
      };
      this.updateFormData(formData);

      this.$router.push({ name: 'ReservationInfo' });
    },
  },

  created() {
    moment.locale('cs');
    this.startDay = moment().format('YYYY-MM-DD');

    this.getAvailableDays();
    this.getDay();
  },
};
</script>
